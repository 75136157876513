<template>
  <el-dialog
    @close="closeDialog"
    title="是否通过审核"
    width="600px"
    :visible="checkVisible"
    :show-close="false"
  >
    <el-radio v-model="radio" label="1">通过</el-radio>
    <el-radio v-model="radio" label="2">拒绝</el-radio>
    <el-form
      label-position="top"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="110px"
    >
      <el-form-item label="备注" prop="message" style="width: 100%">
        <el-input type="textarea" v-model="ruleForm.message" placeholder="请输入备注"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="onSubmit('ruleForm', radio)">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { api } from '/src/api/base';
export const salaryFlowChangeStatus = api()('salaryFlow.changeStatus.json');
export default {
  props: {
    code: String,
    checkVisible: Boolean
  },
  data () {
    return {
      radio: '1',
      ruleForm: {
        message: ''
      },
      rules: {
        //   message: [{ required: true, message: '请输入备注', trigger: 'blur' },],
      }
    }
  },

  methods: {
    closeDialog () {
      this.$emit('update:checkVisible', false);
      this.$emit('close', this.checkVisible);
    },
    loadding (formName, state) {
      salaryFlowChangeStatus({
        taskCode: this.code,
        message: this.ruleForm.message,
        status: state
      }).then(() => {
        this.closeDialog(formName);
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.$parent.loadData();
        // window.history.back(-1);
      });
    },
    onSubmit (formName, state) {
      console.log(state, this.ruleForm.message);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (state === '2') {
            if (!this.ruleForm.message) {
              this.$message({
                message: '请输入备注',
                type: 'info'
              });
            }
            else {
              this.loadding(formName, state)
            }
          }
          else {
            this.loadding(formName, state)
          }

        } else {
          return false;
        }
      });
    },
  }

}
</script>

<style>
</style>